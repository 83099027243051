<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <h2>Bot Provisioning Wizard</h2>
            <hr class="h2" />
            <div class="wizard-steps">
                <div class="completed">
                    <div class="num">&#10003;</div> <span class="step">Basic Details</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="completed">
                    <div class="num">&#10003;</div> <span class="step"> FAQs & Action Triggers</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="completed">
                    <div class="num">&#10003;</div> <span class="step">Email & SMS Actions</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="completed">
                    <div class="num">&#10003;</div> <span class="step"> Transfers</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="completed">
                    <div class="num">&#10003;</div> <span class="step"> Gen AI</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="current">
                    <div class="num">6</div> Finish and Test
                </div>
            </div>
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd0003cc322d392704156a094/emb" :is="'script'">
                </component>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from '../SideBar.vue'

export default {
    name: 'FinishTest',
    components: {
        SideBar
    }
}
</script>