<template>
  <div class="dashboard-container">
    <side-bar />
    <div class="main-content">
      <h2>Account and User Settings</h2>
      <hr class="h2" />
      <div class="tabs">
        <div class="tab-header">

          <button class="tab-button" @click="activeTab = 'user-profile'"
            :class="{ active: activeTab === 'user-profile' }">User Profile</button>
          <button class="tab-button" @click="activeTab = 'company-profile'"
            :class="{ active: activeTab === 'company-profile' }">Company Profile</button>
            
        </div>
        <div class="tab-content">
          <div v-if="activeTab === 'user-profile'">
            <div class="container">
              <component src="https://c2hbe361.caspio.com/dp/76bfd00011eae48515574ff2affe/emb" :is="'script'">
              </component>
            </div>
          </div>
          <div v-if="activeTab === 'company-profile'">
            <div class="container">
              <component src="https://c2hbe361.caspio.com/dp/76bfd000e34c2e449f9d4e8b89a9/emb" :is="'script'">
              </component>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd00011eae48515574ff2affe/emb" :is="'script'"></component>
      </div>
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd000e34c2e449f9d4e8b89a9/emb" :is="'script'"></component>
      </div> -->
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
  name: 'UpdateProfile',
  components: {
    SideBar
  },
  data() {
    return {
      activeTab: 'user-profile' // Default active tab
    }
  },
}
</script>
<style scoped>

.container.tiles {
  /* max-width: 980px; */
  margin-top: 40px;
  display: flex;
  flex-direction: row;
}

.container {
  margin: 10px 0;
}

#btn-new {
  margin: 10px 0;
}

#adsfartessg {
  display: none;
}

#msg-non-admin {
  display: none;
}
</style>