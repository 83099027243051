<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <!-- BreadCrumb datapage -->
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd000467aeb04f5bc4451b5fd/emb" :is="'script'">
                </component>
            </div>
            <h2>Transfers</h2>
            <hr class="h2" />

            <div class="flex-container">
                <div class="left-section">
                    <div class="container">
                        <component src="https://c2hbe361.caspio.com/dp/76bfd00078027a5ed2644359880d/emb" :is="'script'">
                        </component>
                    </div>
                    <!-- submit button -->
                    <div class="container">
                        <component src="https://c2hbe361.caspio.com/dp/76bfd000307823d4aceb46ef9113/emb" :is="'script'">
                        </component>
                    </div>
                </div>
                <div class="right-section">
                    <div class="container">
                        <component src="https://c2hbe361.caspio.com/dp/76bfd000155ff238df2743998991/emb" :is="'script'">
                        </component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from '../SideBar.vue'

export default {
    name: 'PartnerBotTransfers',
    components: {
        SideBar
    }
}
</script>
<style scoped>
.flex-container {
    display: flex;
    flex-direction: row;
}

.right-section {
    max-width: 420px;
}
</style>
