<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <h2>Distributor Dashboard</h2>
            <hr class="h2" />
            <div class="flex-container">
                <div class="left-section">
                    <div class="container">
                        <!-- Summary Tiles -->
                        <component src="https://c2hbe361.caspio.com/dp/76bfd00060a6e6be08ae4f27badc/emb" :is="'script'">
                        </component>
                    </div>
                    <div class="container">
                        <!-- Customers by partner -->
                        <component src="https://c2hbe361.caspio.com/dp/76bfd0000e53c6f408fc44eaa433/emb" :is="'script'">
                        </component>
                    </div>
                    <div class="container">
                        <!-- Customers and bots by partner -->
                        <component src="https://c2hbe361.caspio.com/dp/76bfd000e4c0387fafa44598937b/emb" :is="'script'">
                        </component>
                    </div>

                    <div class="flex-container-1">
                        <div class="container half-width">

                        </div>
                        <div class="container half-width">
                            
                        </div>
                    </div>
                </div>

                <div class="right-section">
                    <div class="container">
                        <!--Opportunities in pipeline pie chart-->
                        <component src="https://c2hbe361.caspio.com/dp/76bfd000d04cc8133d314cd79cf1/emb" :is="'script'">
                        </component>
                    </div>
                    <div class="container">
                        <!-- Bot provisioning status chart -->
                        <component src="https://c2hbe361.caspio.com/dp/76bfd00015613f6bb6e44e36b833/emb"
                                :is="'script'">
                            </component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
    name: 'DistributorDashboard',
    components: {
        SideBar
    }
}
</script>


<style scoped>
.dashboard-container {
    display: flex;
    height: 100vh;
}

.main-content {
    flex: 1;
    padding: 20px;
}

h1 {
    margin-top: 10px;
}

/* .container {
    display: flex;
    max-width: 980px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
}

.container-btn {
    display: flex;
    max-width: 980px;
    margin: 0 auto;
    padding: 20px;
    justify-content: flex-end;
}

.logout-button-container {
    display: flex;
}

.logout-button {
    background-color: #E6C7EB;
    color: black;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 50px;
    cursor: pointer;
    flex: 1;
}

.logout-button:hover {
    background-color: #FE602F;
} */
.flex-container,
.flex-container-1 {
    display: flex;
    flex-direction: row;
}

.flex-container-1 {
    /* max-width: 55%; */
}

.container {
    max-width: 980px;
    margin: 20px;
    flex-grow: 1;
}

.tile {
    display: inline-block;
}

.left-section {
    /* max-width: 760px; */
    width: 55%;
}

.right-section {
    max-width: 400px;
    width: 25%;
}

.container.half-width {
    width: 50%;
}
</style>