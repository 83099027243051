<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <h2>Demo Bot Setup</h2>
            <hr class="h2" />
            <div class="wizard-steps">
                <div class="completed">
                    <div class="num">&#10003;</div> <span class="step">Basic Details</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="completed">
                    <div class="num">&#10003;</div> <span class="step">View FAQs</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="current">
                    <div class="num">3</div> <span class="step">Transfers and Emails</span>
                    <span class="arrow">&#8594;</span>
                </div>

                <div class="num">4</div> Test And Finish
            </div>
            <div class="call-transfer">
                <h4>Call Transfer</h4>
                <p>The demo bot can seamlessly transfer calls to a human agent if needed. This ensures a smooth
                    transition for more complex inquiries or when a human touch is required. You can configure the bot
                    to transfer calls based on specific keywords, caller intent, or after a certain number of
                    interactions.</p>
                <div class="container">
                    <component src="https://c2hbe361.caspio.com/dp/76bfd000da8061cfd2a5462899dd/emb" :is="'script'">
                    </component>
                </div>
            </div>
            <div class="booking">
                <h4>Booking</h4>
                <p>The demo bot can schedule appointments or bookings on behalf of your business. It can access your
                    scheduling system, check for availability, and confirm bookings with customers. This feature
                    automates the booking process, reducing manual effort and improving customer satisfaction.</p>
            </div>
            <div class="call-back">
                <h4>Call back</h4>
                <p>If a customer needs to be contacted later, the demo bot can schedule a call back. It can collect the
                    customer's preferred time and contact information, ensuring they receive a timely response without
                    waiting on hold.</p>
            </div>
            <div class="Email Request">
                <h4>Email Request</h4>
                <div class="container">
                    <component src="https://c2hbe361.caspio.com/dp/76bfd000020a9cbebee24b1582ea/emb" :is="'script'">
                    </component>
                </div>

            </div>

            <!--Data page DemoSetup_3_btnSubmitTransfersEmail -->
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd000b5b463269ab04ae38c0f/emb" :is="'script'">
                </component>
            </div>
        </div>
    </div>
</template>
<script>
import SideBar from '../SideBar.vue'
export default {
    name: 'MainConfig',
    components: {
        SideBar
    }
}
</script>
<style scoped>
h4 {
    text-align: left;
    padding-left: 10px;
    font-size: 25px;
    font-weight: bold;
}

p {
    text-align: left;
    padding-left: 10px;
}
</style>
