<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <h2>Version History and Upcoming Features</h2>
            <hr class="h2" />
            <div class="flex-container">
                <div class="left-section">
                    <div class="ver-box">
                        <h3>Current Version</h3>

                        <h4>Version 1.3</h4>
                        <p>
                            <b>Release Date: </b> Dec 1st, 2024<br>
                            <b>Summary of changes: </b><br>
                        <ul>
                            <li>Enhanced bot discovery page with new file download feature</li>
                            <li>Added UX flow to effortlessly create demo bots for partners</li>
                            <li>Introduced bot provisioning wizard to create custom bots using persona templates</li>
                            <li>Added PoC and MSP persona template options</li>
                        </ul>
                        </p>
                    </div>

                    <div class="ver-box">
                        <h3>Release History</h3>
                        <h4>Version 1.2</h4>
                        <p>
                            <b>Release Date: </b> Oct 1st, 2024<br>
                            <b>Summary of changes: </b><br>
                        <ul>
                            <li>View last 50 call logs (voice bots)</li>
                            <li>View last 50 conversations</li>
                            <li>View conversation transcripts</li>
                        </ul>
                        </p>
                        <h4>Version 1.1</h4>
                        <p>
                            <b>Release Date: </b> Sep 1st, 2024<br>
                            <b>Summary of changes: </b><br>
                        <ul>
                            <li>Add new opportunity</li>
                            <li>Add new customer</li>
                            <li>View customer bots and configuration</li>
                            <li>Discovery form for new customer bot</li>
                        </ul>
                        </p>
                        <h4>Version 1.0</h4>
                        <p>
                            <b>Release Date: </b> Aug 1st, 2024<br>
                            <b>Summary of changes: </b><br>
                        <ul>
                            <li>Partner dashboard with overview statistics</li>
                            <li>View and filter opportunities</li>
                            <li>View and filter customers</li>
                            <li>View and update account login details and company profile</li>
                        </ul>
                        </p>
                    </div>
                </div>

                <div class="right-section">
                    <div id="future-rel">
                        <h3>Upcoming Features</h3>
                        <h4>Version 1.4</h4>
                        <p>
                            <b>Planned Release Date: </b>Jan 1st, 2025<br>
                            <b>Summary of changes: </b>
                        </p>
                        <ul>
                            <li>Medical personas</li>
                            <li>Custom bot creation option (without persona selection)</li>
                            <li>Thai language option for bot personas</li>

                        </ul>
                    </div>
                    <div>
                        <h3>FAQs</h3>
                        <p>
                            <b>Question: How can I view the list of bots for my customers?</b><br>
                            Go to Partners → Select a Partner → Select Customer → Click on the customer name to view the
                            details of the customer, including
                            list of bots
                        </p>
                        <p>
                            <b>Question: Where do I see conversation logs?</b><br>
                            Go to Partners → Select a Partner → Select Customer → View the list of customer bots → Click
                            'Conversation History' on a bot card
                        </p>
                        <p>
                            <b>Question: Where can I create a demo bot for my customer?</b><br>
                            Go to Partners → Select a Partner → Select Customer → View the list of customer bots → Click
                            on the New Bot button → Choose Setup Demo Bot.
                        </p>

                        <p>
                            <b>Question: Where can I fill out a discovery form for my customer?</b><br>
                            Go to Partners → Select a Partner → Select Customer → View the list of customer bots → Click
                            the New
                            Bot button → Select Bot Discovery.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
    name: 'DistributorDashboard',
    components: {
        SideBar
    }
}
</script>


<style scoped>
.dashboard-container {
    display: flex;
    height: 100vh;
}

.main-content {
    flex: 1;
    padding: 20px;
}

h1 {
    margin-top: 10px;
}

#future-rel {
    border: 2px solid #fe602f;
    padding: 10px;
    margin-bottom: 40px;
    max-width: 400px;
}

.ver-box {
    padding: 10px 0;
    margin-bottom: 20px;
}

/* .container {
    display: flex;
    max-width: 980px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
}

.container-btn {
    display: flex;
    max-width: 980px;
    margin: 0 auto;
    padding: 20px;
    justify-content: flex-end;
}

.logout-button-container {
    display: flex;
}

.logout-button {
    background-color: #E6C7EB;
    color: black;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 50px;
    cursor: pointer;
    flex: 1;
}

.logout-button:hover {
    background-color: #FE602F;
} */
.flex-container {
    display: flex;
    flex-direction: row;
    text-align: left;
    padding-top: 20px;
}

.container {
    max-width: 980px;
    margin: 20px;
}

.left-section {
    /* max-width: 760px; */
    width: 45%;
    margin-left: 30px;
}

.right-section {
    width: 25%;
    max-width: 600px;
}

h3 {
    font-size: 22px;
    color: grey;
    margin-bottom: 20px;
}

h4 {
    font-size: 18px;
    margin: 20px 0 10px 0;
}
</style>