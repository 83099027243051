<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <h2>Bot Provisioning Wizard</h2>
            <hr class="h2" />
            <div class="wizard-steps">
                <div class="current">
                    <div class="num">1</div> <span class="step">Basic Details</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="num">2</div> <span class="step"> Add FAQs</span>
                <span class="arrow">&#8594;</span>
                <div class="num">3</div> Data and Emails
                <span class="arrow">&#8594;</span>
                <div class="num">4</div> Transfers
                <span class="arrow">&#8594;</span>
                <div class="num">5</div> Gen AI
                <span class="arrow">&#8594;</span>
                <div class="num">6</div> Test and Finish
            </div>
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd000158ea59b7d314d8d9c69/emb" :is="'script'">
                </component>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from '../SideBar.vue'

export default {
    name: 'MainConfig',
    components: {
        SideBar
    }
}
</script>

<style>
.wizard-steps {
    text-align: left;
    background-color: #fafafa;
    border-top: #e5e5e5 1px solid;
    border-bottom: #e5e5e5 1px solid;
    width: 95%;
    padding: 10px;
    color: #666;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: bold;
    margin-bottom: 30px;
}

.num {
    display: inline-block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 5px;
    background: #fff;
    text-align: center;
    margin-left: 10px;
    margin-right: 5px;
    vertical-align: middle;
    border: 1px solid #e5e5e5;
}

.completed,
.current {
    display: inline-block;
}

.completed .step,
.completed .arrow {
    color: #42a593;
}

.current .step,
.current .arrow {
    color: #e45f2b;
}

.completed .num {
    color: white;
    background-color: #64CEBB;
    border: none;
    padding: 3px 5px 7px 5px;
}

.current .num {
    color: white;
    background-color: #FE602F;
    border: none;
}

.arrow {
    margin-left: 5px;
    font-size: 22px;
    font-weight: bolder;
}
</style>