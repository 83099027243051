<template>
  <div class="dashboard-container">
    <side-bar />
    <div class="main-content">
      <!--Breadcrumbs and title embedded within below data page-->
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd00014f8b8eb37eb4a46870e/emb" :is="'script'">
        </component>
      </div>


      <div class="tabs">
        <div class="tab-header">

          <button class="tab-button" @click="this.$router.push('/partner/call-logs')"
            :class="{ active: activeTab === 'call-logs' }">Call Logs</button>
          <button class="tab-button" @click="this.$router.push('/partner/conversation-history')"
            :class="{ active: activeTab === 'conv-history' }">Conversation History</button>
          <button class="tab-button" @click="this.$router.push('/partner/view-bot')"
            :class="{ active: activeTab === 'bot-config' }">Bot Configuration</button>
        </div>
        <div class="tab-content">
          <div v-if="activeTab === 'bot-config'">

            <div class="flex-container">
              <div class="left-section column">
                <div class="container">
                  <component src="https://c2hbe361.caspio.com/dp/76bfd0005c9fa86a017b42c5ba24/emb" :is="'script'">
                  </component>
                </div>
              </div>
              <div class="right-section column">
                <div class="container">
                  <component src="https://c2hbe361.caspio.com/dp/76bfd00028777ee890cc41a4883b/emb" :is="'script'">
                  </component>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
  name: 'ViewBotConfig',
  components: {
    SideBar,
  },
  data() {
    return {
      activeTab: 'bot-config' // Default active tab
    }
  },
}
</script>

<style scoped>
.side-section {
  width: calc(100% / 4);
  /* Occupies one-third of the main content width */
  float: left;
  /* Position it to the right */
}

.flex-container,
.top-flex-container {
  display: flex;
  flex-direction: row;
}

/* .top-flex-container {
  text-align: -webkit-right;
} */

.top-left {
  flex-grow: 1;
  justify-content: flex-start;
}

.top-right {
  flex-grow: 1;
  justify-content: flex-end;
}

.column {
  text-align: center;
  /* Center content within columns */
  /* Vertically center content within columns */
  margin: 0 auto;
}

.left-section {
  width: auto;
  display: inline-block;
}

.centre-section {
  max-width: 400px;
  padding-top: 40px;
}

.right-section {
  max-width: 220px;
}
</style>
