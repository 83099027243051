<template>
  <div class="dashboard">
    <div class="content">
      <h1>Discovery Sheet</h1>
      <hr class="h1" />
      <div class="content-flex">
        <div class="form-container">
          <!-- Bot Details Section -->
          <div class="bot-details">
            <div class="form-group">
              <label for="bot-name">Bot Name</label>
              <input type="text" name="bot-name" id="bot-name" class="form-control" placeholder="Enter bot name"
                v-model="botName" required />
            </div>
            <div class="form-group">
              <label for="subscription-start">Subscription Start Date</label>
              <input type="date" name="subscription-start" id="subscription-start" v-model="formattedDate"
                :min="minDate" />
            </div>
            <div class="form-group">
              <label for="bot-type">Bot Type</label>
              <select id="bot-type" v-model="selectedBotType">
                <option value="">Select one</option>
                <option value="chat">Chat Bot</option>
                <option value="voice">Voice Bot</option>
              </select>
            </div>
            <!-- Radio btn -->
            <div class="bot-details-card-group">
              <!-- chat bot radio btn -->
              <div class="bot-type-section" v-if="isChatBot">
                <h4>Chat Bot Tier</h4>
                <div class="card-grid">
                  <label class="card" for="chat-poc">
                    <input type="radio" id="chat-poc" v-model="selectedBotTier" value="PoC">
                    <div class="card-content">
                      <h5>PoC</h5>
                      <p>A trial solution, designed to support enterprise deals by providing limited access for
                        evaluation</p>
                    </div>
                  </label>
                  <label class="card" for="chat-support">
                    <input type="radio" id="chat-support" v-model="selectedBotTier" value="support">
                    <div class="card-content">
                      <h5>Support</h5>
                      <p>A 24/7 bot for your website that answers questions, collects user information and provides it
                        back to the business via email.
                      </p>
                    </div>
                  </label>
                  <label class="card" for="chat-sales">
                    <input type="radio" id="chat-sales" v-model="selectedBotTier" value="sales">
                    <div class="card-content">
                      <h5>Sales</h5>
                      <p>A complex bot with all the above, plus the ability to provide more complex tiered information,
                        make bookings, and collect reviews.</p>
                    </div>
                  </label>

                  <label class="card" for="chat-enterprise">
                    <input type="radio" id="chat-enterprise" v-model="selectedBotTier" value="chat-enterprise">
                    <div class="card-content">
                      <h5> Chat Enterprise</h5>
                      <p>A custom bot, including integrations into systems through APIs and the creation of custom
                        frameworks.</p>
                    </div>
                  </label>
                </div>
              </div>
              <!-- voice bot radio btn -->
              <div class="bot-type-section" v-if="isVoiceBot">
                <h4>Voice Bot Tier</h4>
                <div class="card-grid">
                  <label class="card" for="voice-poc">
                    <input type="radio" id="voice-poc" v-model="selectedBotTier" value="voicePoC">
                    <div class="card-content">
                      <h5>PoC</h5>
                      <p>
                        A trial solution, designed to support enterprise deals by providing limited access for
                        evaluation.</p>
                    </div>
                  </label>
                  <label class="card" for="voice-business">
                    <input type="radio" id="voice-business" v-model="selectedBotTier" value="business">
                    <div class="card-content">
                      <h5>Business</h5>
                      <p>A basic voice bot, designed for small businesses like that focuses on call answering, routing
                        and providing information over the phone.</p>
                    </div>
                  </label>
                  <label class="card" for="voice-professional">
                    <input type="radio" id="voice-professional" v-model="selectedBotTier" value="professional">
                    <div class="card-content">
                      <h5>Professional</h5>
                      <p>A complex bot with all the above, plus the ability to provide more complex tiered information,
                        collect caller details, make bookings, and send emails & text messages. </p>
                    </div>
                  </label>
                  <label class="card" for="voice-enterprise">
                    <input type="radio" id="voice-enterprise" v-model="selectedBotTier" value="voice-enterprise">
                    <div class="card-content">
                      <h5>Enterprise</h5>
                      <p>A custom bot, including integrations into systems through APIs and the creation of custom
                        frameworks.
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- Feature section list -->
          <div class="feature-list-group" v-if="selectedBotTier">
            <!-- poc feature list-->
            <div class="PoC-feature-list" v-if="selectedBotTier === 'PoC'">
              <ul>
                <li>5x scripted FAQs </li>
                <li>Generative AI </li>
                <li>10,000-character data source </li>
                <li>2x data collection points </li>
                <li>1x email integration </li>
                <li>PoC is available for 7 days </li>
                <li>PAYG rates for calls are not included </li>
                <li>The use of PoC is at the discretion of Trimate
                  AI and is intended to help secure larger enterprise
                  clients </li>
              </ul>
            </div>
            <!-- support feature list -->
            <div class="support-feature-list" v-if="selectedBotTier === 'support'">
              <ul>
                <li>15x scripted FAQs </li>
                <li>Generative AI </li>
                <li>5,000-character data source </li>
                <li>3x data collection points </li>
                <li>1x email destinations </li>
              </ul>
            </div>
            <!-- Sales feature list -->
            <div class="sales-feature-list" v-if="selectedBotTier === 'sales'">
              <ul>
                <li>30x scripted FAQs </li>
                <li>3x tiered FAQs</li>
                <li>Generative AI </li>
                <li>10,000-character data source </li>
                <li>10x data collection points </li>
                <li>3x email destinations </li>
                <li>3x booking calendars </li>
                <li>1x review</li>
              </ul>
            </div>
            <!-- Chat enterprise feature list -->
            <div class="chat-enterprise-feature-list" v-if="selectedBotTier === 'chat-enterprise'">
              <ul>
                <li>Unlimited scripted FAQs </li>
                <li>Unlimited tiered FAQs </li>
                <li>Generative AI</li>
                <li>10,000-character data source </li>
                <li>Unlimited data collection points </li>
                <li>Unlimited email destinations</li>
                <li>Unlimited booking calendars </li>
                <li>Data Source </li>
                <li>Website Scraping </li>
                <li>CRM integrations </li>
              </ul>
            </div>
            <!-- voicePoC feature list -->
            <div class="voicePoC-feature-list" v-if="selectedBotTier === 'voicePoC'">
              <ul>
                <li>5x scripted FAQs </li>
                <li>Generative AI </li>
                <li>10,000-character data source </li>
                <li>1x destinations call transfer </li>
                <li>2x data collection points </li>
                <li>1x email integration </li>
                <li>PoC is available for 7 days </li>
                <li>PAYG rates for calls are not included </li>
                <li>Tria SIP only </li>
                <li>The use of PoC is at the discretion of Trimate AI and is intended to help secure larger enterprise
                  clients </li>
              </ul>
            </div>
            <!-- Business feature list -->
            <div class="business-feature-list" v-if="selectedBotTier === 'business'">
              <ul>
                <li>15x FAQs </li>
                <li>Generative AI </li>
                <li>5,000-character data source </li>
                <li>10x destinations call transfer</li>
              </ul>
            </div>
            <!-- Professional feature list -->
            <div class="professional-feature-list" v-if="selectedBotTier === 'professional'">
              <ul>
                <li>30x scripted FAQs </li>
                <li>3x tiered FAQs</li>
                <li>Generative AI </li>
                <li>10,000-character data source </li>
                <li>25x destinations call transfer</li>
                <li>10x data collection points </li>
                <li>3x email destinations </li>
                <li>3x booking calendars</li>
                <li>Text Notifications </li>

              </ul>
            </div>
            <!-- Enterprise feature list -->
            <div class="enterprise-feature-list" v-if="selectedBotTier === 'voice-enterprise'">
              <ul>

                <li> Unlimited scripted FAQs </li>
                <li>Unlimited tiered FAQs </li>
                <li>Generative AI </li>
                <li>10,000-character data source </li>
                <li>Unlimited destinations call transfer </li>
                <li>Unlimited data collection points </li>
                <li>Unlimited email destinations </li>
                <li>Unlimited booking calendars </li>
                <li>Text Notifications </li>
                <li>Data Source </li>
                <li>Website Scraping </li>
                <li>CRM integrations </li>
                <li>Payment Integrations </li>
              </ul>
            </div>
          </div>
          <!-- Greeting section -->
          <div class="greeting-section" v-if="selectedBotTier === 'PoC' ||
            selectedBotTier === 'sales' ||
            selectedBotTier === 'support' ||
            selectedBotTier === 'chat-enterprise' ||
            selectedBotTier === 'voicePoC' ||
            selectedBotTier === 'business' ||
            selectedBotTier === 'voice-enterprise' ||
            selectedBotTier === 'professional'">
            <div class="input-group">
              <label for="">Greeting : <font-awesome-icon icon="fa-circle-question" class="icon" v-b-tooltip.hover
                  title="Default welcome greeting the bot will say when user calls or starts a chat." /></label>
              <textarea name="" id="" cols="30" rows="10"
                placeholder="Example: Welcome to Trimate AI, how can I help you?" v-model="greeting"> </textarea>
            </div>
            <div class="input-group">
              <label for="">No Match Action:<font-awesome-icon icon="fa-circle-question" class="icon" v-b-tooltip.hover
                  title="This is the what the bot will use when there is no match to any programmed responses.You should type generator to handle this more dynamically." /></label>
              <textarea name="" id="" cols="30" rows="10"
                placeholder="Example: No Match1: I am sorry could you say that again? No match 2: Transfer to Reception"
                v-model="noMatchAction"> </textarea>
            </div>
            <div class="input-group">
              <label for="">No Input Action: <font-awesome-icon icon="fa-circle-question" class="icon" v-b-tooltip.hover
                  title="This is the response that bot will use when the caller goes silent." /></label>
              <textarea name="" id="" cols="30" rows="10" placeholder="Example: Repeat the previous reply "
                v-model="noInputAction"> </textarea>
            </div>

          </div>
          <!-- Features Section -->
          <div class="bot-features" v-if="
            selectedBotTier === 'PoC' ||
            selectedBotTier === 'sales' ||
            selectedBotTier === 'support' ||
            selectedBotTier === 'chat-enterprise' ||
            selectedBotTier === 'voicePoC' ||
            selectedBotTier === 'business' ||
            selectedBotTier === 'voice-enterprise' ||
            selectedBotTier === 'professional'

          ">
            <h4>Features</h4>
            <p>Select the features you want to add to the bot</p>
            <div class="checkbox-group">
              <label><input type="checkbox" id="featureFaqs" v-model="showFaqSection" />
                FAQs <font-awesome-icon icon="fa-circle-question" class="icon" v-b-tooltip.hover
                  title="FAQ offers scripted answers to common questions" /></label>
              <label v-if="
                selectedBotTier === 'sales' ||
                selectedBotTier === 'chat-enterprise' ||
                selectedBotTier === 'voice-enterprise' ||
                selectedBotTier === 'professional'
              "><input type="checkbox" id="featureTieredFaqs" v-model="showTieredFaqSection" />
                Tiered FAQs <font-awesome-icon icon="fa-circle-question" class="icon" v-b-tooltip.hover
                  title="Tiered FAQs provide logic-based responses for more specific info" /></label>
              <label v-if="
                selectedBotTier === 'voicePoC' ||
                selectedBotTier === 'business' ||
                selectedBotTier === 'voice-enterprise' ||
                selectedBotTier === 'professional'
              "><input type="checkbox" id="featureCallTransfer" v-model="showCallTransferSection" />
                Call Transfers <font-awesome-icon icon="fa-circle-question" class="icon" v-b-tooltip.hover
                  title="Destinations for seamless call transfers" /> </label>
              <label v-if="
                selectedBotTier === 'PoC' ||
                selectedBotTier === 'voicePoC' ||
                selectedBotTier === 'sales' ||
                selectedBotTier === 'support' ||
                selectedBotTier === 'chat-enterprise' ||
                selectedBotTier === 'voice-enterprise' ||
                selectedBotTier === 'professional'
              "><input type="checkbox" id="featureDataCollection" v-model="showDataCollectionSection" />
                Data Collection <font-awesome-icon icon="fa-circle-question" class="icon" v-b-tooltip.hover
                  title="Data collection lets the bot gather user info for emails, bookings, and more" /></label>
              <label v-if="
                selectedBotTier === 'sales' ||
                selectedBotTier === 'chat-enterprise' ||
                selectedBotTier === 'voice-enterprise' ||
                selectedBotTier === 'professional'
              "><input type="checkbox" id="featureBooking" v-model="showBookingSection" />
                Booking <font-awesome-icon icon="fa-circle-question" class="icon" v-b-tooltip.hover
                  title="Booking feature checks calendars and sends invites based on availability" /></label>

              <label v-if="
                selectedBotTier === 'PoC' ||
                selectedBotTier === 'voicePoC' ||
                selectedBotTier === 'sales' ||
                selectedBotTier === 'support' ||
                selectedBotTier === 'chat-enterprise' ||
                selectedBotTier === 'voice-enterprise' ||
                selectedBotTier === 'professional'
              "><input type="checkbox" id="featureEmail" v-model="showEmailSection" />
                Email <font-awesome-icon icon="fa-circle-question" class="icon" v-b-tooltip.hover
                  title="Email feature lets the bot send info or trigger notifications to the team." /></label>
              <label v-if="

                selectedBotTier === 'voice-enterprise' ||
                selectedBotTier === 'professional'
              "><input type="checkbox" id="featureSms" v-model="showSmsSection" />
                Text/SMS <font-awesome-icon icon="fa-circle-question" class="icon" v-b-tooltip.hover
                  title="Our text feature enables the bot to send SMS messages" /></label>
              <label v-if="
                selectedBotTier === 'PoC' ||
                selectedBotTier === 'voicePoC' ||
                selectedBotTier === 'sales' ||
                selectedBotTier === 'support' ||
                selectedBotTier === 'chat-enterprise' ||
                selectedBotTier === 'business' ||
                selectedBotTier === 'voice-enterprise' ||
                selectedBotTier === 'professional'
              "><input type="checkbox" id="featureGenAi" v-model="showGenAiSection" />
                Generative AI <font-awesome-icon icon="fa-circle-question" class="icon" v-b-tooltip.hover
                  title="Generative AI provides smart fallback answers with a brief delay" /></label>
              <label v-if="
                selectedBotTier === 'PoC' ||
                selectedBotTier === 'voicePoC' ||
                selectedBotTier === 'sales' ||
                selectedBotTier === 'support' ||
                selectedBotTier === 'chat-enterprise' ||
                selectedBotTier === 'business' ||
                selectedBotTier === 'professional' ||
                selectedBotTier === 'voice-enterprise'
              "><input type="checkbox" id="featureDataSource" v-model="showDataSourceSection" />
                Data Source <font-awesome-icon icon="fa-circle-question" class="icon" v-b-tooltip.hover
                  title="Data Source" /></label>
              <label v-if="
                selectedBotTier === 'chat-enterprise' ||
                selectedBotTier === 'voice-enterprise'
              ">
                <input type="checkbox" id="featureDataStore" v-model="showDataStoreSection"
                  @change="showDataStoreMessage" />
                Data Store
                <font-awesome-icon icon="fa-circle-question" class="icon" v-b-tooltip.hover
                  title="Data Store gives bots access to a knowledge base, ideal for chat and e-commerce, with possible delays" />
              </label>
              <!-- Error Modal -->
              <div class="modal-overlay" v-if="showDataStore">
                <div class="modal-content">
                  <span class="close" @click="showDataStore = false">&times;</span>
                  <h3>Data Store</h3>
                  <p>Please provide the document as an attachment separately.</p>
                  <button @click="showDataStore = false">OK</button>
                </div>
              </div>
              <label v-if="
                selectedBotTier === 'sales'
              "><input type="checkbox" id="featureReviews" v-model="showReviewsSection" />
                Reviews <font-awesome-icon icon="fa-circle-question" class="icon" v-b-tooltip.hover
                  title="Review feature collects user feedback and CSAT ratings post-interaction" /></label>
              <label v-if="
                selectedBotTier === 'chat-enterprise' ||
                selectedBotTier === 'voice-enterprise'">
                <input type="checkbox" id="featureCrm" v-model="showCrmSection" />
                CRM <font-awesome-icon icon="fa-circle-question" class="icon" v-b-tooltip.hover
                  title="CRM integration lets the bot retrieve and update info in your CRM system" /></label>

              <label v-if="
                selectedBotTier === 'chat-enterprise' ||
                selectedBotTier === 'voice-enterprise'">
                <input type="checkbox" id="featurePayments" v-model="showPaymentsSection" />
                Payments <font-awesome-icon icon="fa-circle-question" class="icon" v-b-tooltip.hover
                  title="Our payment feature enables the bot to securely collect payments from users" />
              </label>
            </div>
          </div>

          <!-- FAQ Section -->
          <div class="faq-section" v-if="showFaqSection">
            <hr class="section">
            <h4>FAQs</h4>
            <p>Scripted responses to commonly asked questions</p>
            <div class="form-group" v-for="(faq, index) in faqs" :key="index">
              <div class="input-group">
                <div class="name-phrase">
                  <label :for="`faqName-${index}`">{{ index + 1 }}. FAQ Name:</label>
                  <input type="text" :id="`faqName-${index}`" placeholder="Enter FAQ name" v-model="faq.name" />
                </div>
                <div class="name-phrase" style="position: relative;">
                  <label :for="`faqPhrase-${index}`">Example Phrases:</label>
                  <input type="text" :id="`faqPhrase-${index}`" placeholder="Enter example phrase" v-model="faq.phrase"
                    @input="getSuggestions(index)" @keydown.enter="selectSuggestion(index)" />
                  <!-- suggestion list -->
                  <ul v-if="suggestions[index] && suggestions[index].length" class="suggestions">
                    <li v-for="(suggestion, sIndex) in suggestions[index]" :key="sIndex"
                      @click="selectSuggestion(index, sIndex)" :class="{ active: suggestionIndex[index] === sIndex }">
                      {{ suggestion }}
                    </li>
                  </ul>
                </div>
              </div>
              <label :for="`faqResponse-${index}`" style="text-align: left;">Response:</label>
              <textarea :id="`faqResponse-${index}`" placeholder="Enter response" v-model="faq.response"
                rows="10"></textarea>
            </div>
            <button @click="addFaq">Add FAQ</button>
            <!-- Error Modal -->
            <div v-if="showFaqLimitError" class="modal-overlay">
              <div class="modal-content">
                <span class="close" @click="showFaqLimitError = false">&times;</span>
                <h3>FAQ Limit Reached</h3>
                <p>You reached to the maximum limit of FAQs for the {{
                  selectedBotTier }} tier.</p>
                <button @click="showFaqLimitError = false">OK</button>
              </div>
            </div>

            <button class="del-btn" @click="deleteItem('faqs', index)">Delete FAQ</button>
          </div>
          <!-- Tiered FAQs Section -->
          <div class="tiered-faq-section" v-if="showTieredFaqSection">
            <hr class="section">
            <h4>Tiered FAQs</h4>
            <p>Conditional responses to commonly asked questions</p>
            <div class="form-group" v-for="(tfaq, index) in tieredFaqs" :key="index">
              <div class="input-group">
                <label :for="`tfaq-name-${index}`">{{ index + 1 }}. Name:</label>
                <input type="text" :id="`tfaq-name-${index}`" placeholder="e.g. Consultation cost" v-model="tfaq.name">
              </div>
              <div class="input-group">
                <label :for="`tfaq-phrase-${index}`">Example Phrases:</label>
                <input type="text" :id="`tfaq-phrase-${index}`"
                  placeholder="e.g. How much do you charge for consultation?" v-model="tfaq.phrase">
              </div>
              <!-- Conditional Responses -->
              <div class="conditional-responses">
                <div class="if-then-group" v-for="(condition, cIndex) in tfaq.conditions" :key="cIndex">
                  <div class="condition-input">
                    <label :for="`condition-parameter-${index}-${cIndex}`">IF</label>
                    <input type="text" :id="`condition-parameter-${index}-${cIndex}`"
                      placeholder="e.g. Existing customer" v-model="condition.parameter">
                  </div>
                  <div class="condition-input">
                    <label :for="`condition-value-${index}-${cIndex}`">=</label>
                    <input type="text" :id="`condition-parameter-${index}-${cIndex}`" placeholder="Yes"
                      v-model="condition.value">
                  </div>
                  <div class="condition-input">
                    <label :for="`condition-response-${index}-${cIndex}`">THEN</label>
                    <textarea :id="`condition-response-${index}-${cIndex}`"
                      placeholder="e.g. Consultation cost for existing customers is AUD 50"
                      v-model="condition.response"></textarea>
                  </div>
                </div>
              </div>
              <button @click="addCondition(index)">Add Condition</button>


              <button class="del-btn" @click="deleteCondition(index, cIndex)">Remove Condition</button>

            </div>
            <button @click="addTieredFaq">Add Tiered FAQ</button>
            <!-- Error Modal -->
            <div class="modal-overlay" v-if="showTieredFaqLimitError">
              <div class="modal-content">
                <span class="close" @click="showTieredFaqLimitError = false">&times;</span>
                <h3>Tiered FAQ Limit Reached</h3>
                <p>You reached to the maximum limit of Tiered FAQs for
                  the {{ selectedBotTier }} tier.</p>
                <button @click="showTieredFaqLimitError = false">OK</button>
              </div>
            </div>
            <button class="del-btn" @click="deleteItem('tieredFaqs', index)">Delete Tiered FAQ</button>
          </div>


          <!-- Call Transfer Section -->
          <div class="call-transfer-section" v-if="showCallTransferSection">
            <hr class="section">
            <h4>Call Transfer</h4>
            <p>
              The bot will transfer the call to the specified person upon
              request.
            </p>
            <div class="form-group" v-for="(transfer, index) in callTransfers" :key="index">
              <label :for="`transfer-destination-${index}`">{{ index + 1 }}. Destination:</label>
              <input type="text" :id="`transfer-destination-${index}`" placeholder="Person or Department"
                v-model="transfer.destination" />
              <label :for="`transfer-did-${index}`">DID Number:</label>
              <input type="text" :id="`transfer-did-${index}`" placeholder="613xxxxxxx" v-model="transfer.did" />
            </div>
            <button @click="addCallTransfer">Add Call Transfer</button>
            <!-- Error Modal -->
            <div class="modal-overlay" v-if="showCallTransferLimitError">
              <div class="modal-content">
                <span class="close" @click="showCallTransferLimitError = false">&times;</span>
                <h3>Call Transfer Limit Reached</h3>
                <p>You reached to the maximum limit of Call Transfer for
                  the {{ selectedBotTier }} tier.</p>
                <button @click="showCallTransferLimitError = false">OK</button>
              </div>
            </div>
            <button class="del-btn" @click="deleteItem('callTransfers', index)">Delete Call Transfer</button>

          </div>


          <!-- Data Collection Section -->
          <div class="data-collection" v-if="showDataCollectionSection">
            <hr class="section">
            <h4>Data Collection</h4>
            <p>
              Specify the data points to be included in emails, bookings, or
              CSAT.
            </p>
            <div class="form-group" v-for="(dataCollection, index) in dataCollections" :key="index">
              <label :for="`data-question-${index}`">{{ index + 1 }}. Question:</label>
              <input type="text" :id="`data-question-${index}`" placeholder="Enter question"
                v-model="dataCollection.question" />
              <label for="data-module">Select Module:</label>
              <div class="dropdown">
                <input type="text" class="dropdown-input" placeholder="Select Module" readonly />
                <div class="dropdown-content">
                  <label><input type="checkbox" value="Booking 1" v-model="dataCollection.modules.booking1" />
                    Bookings</label>
                  <label><input type="checkbox" value="Send Email 1" v-model="dataCollection.modules.sendEmail1" />
                    1.Emails
                  </label>
                  <label><input type="checkbox" value="Send Email 2" v-model="dataCollection.modules.sendEmail2" />
                    2. Emails
                  </label>
                  <label><input type="checkbox" value="Send Email 3" v-model="dataCollection.modules.sendEmail3" />
                    3. Emails
                  </label>
                </div>
              </div>
              <label :for="`data-comment-${index}`">Comment:</label>
              <textarea :id="`data-comment-${index}`" placeholder="Enter comment"
                v-model="dataCollection.comment"></textarea>
            </div>
            <button @click="addDataCollection">Add Data Field</button>
            <!-- Error Modal -->
            <div class="modal-overlay" v-if="showDataCollectionLimitError">
              <div class="modal-content">
                <span class="close" @click="showDataCollectionLimitError = false">&times;</span>
                <h3>Data Collection Limit Reached</h3>
                <p>You reached to the maximum limit of Data Collection for
                  the {{ selectedBotTier }} tier.</p>
                <button @click="showDataCollectionLimitError = false">OK</button>
              </div>
            </div>
            <button class="del-btn" @click="deleteItem('dataCollections', index)">Delete Data Field</button>
          </div>


          <!-- Booking Module Section -->
          <div class="booking-module" v-if="showBookingSection">
            <hr class="section">
            <h4>Booking Module</h4>
            <p>The bot can book meetings in people's calendars.</p>
            <div class="form-group" v-for="(booking, index) in bookings" :key="index">
              <label :for="`booking-name-${index}`">{{ index + 1 }}. Name:</label>
              <input type="text" :id="`booking-name-${index}`" v-model="booking.name" placeholder="Enter name" />
              <label :for="`booking-email-${index}`">Email Address:</label>
              <input type="email" :id="`booking-email-${index}`" v-model="booking.email" placeholder="Enter email" />
            </div>
            <button class="booking-btn" @click="addBooking">Add Booking</button>
            <!-- Error Modal -->
            <div class="modal-overlay" v-if="showBookingLimitError">
              <div class="modal-content">
                <span class="close" @click="showBookingLimitError = false">&times;</span>
                <h3>Booking Limit Reached</h3>
                <p>You reached to the maximum limit of Booking for
                  the {{ selectedBotTier }} tier.</p>
                <button @click="showBookingLimitError = false">OK</button>
              </div>
            </div>
            <button class="del-btn" @click="deleteItem('bookings', index)">Delete Booking</button>
          </div>

          <!-- Email Section -->

          <div class="email-module" v-if="showEmailSection">
            <hr class="section">
            <h4>Email Module</h4>
            <p>The bot can create and send emails using various templates.</p>
            <div class="form-group" v-for="(email, index) in emails" :key="index">
              <div class="input-group">
                <div class="name-phrase">
                  <label :for="`email-trigger-${index}`">{{ index + 1 }}. Trigger Phrase:</label>
                  <input type="text" :id="`email-trigger-${index}`" placeholder="Example: I have an invoice question"
                    v-model="email.trigger" />
                </div>
                <div class="name-phrase">
                  <label :for="`email-address-${index}`">Email Address:</label>
                  <input type="text" :id="`email-address-${index}`" placeholder="Example: john@example.com"
                    v-model="email.address" />
                </div>
              </div>
              <label :for="`email-template-${index}`" style="text-align: left;">Email Template:</label>
              <textarea :id="`email-template-${index}`" placeholder="Enter email template here" v-model="email.template"
                rows="10"></textarea>
            </div>
            <button @click="addEmail">Add Template</button>
            <!-- Error Modal -->
            <div class="modal-overlay" v-if="showEmailLimitError">
              <div class="modal-content">
                <span class="close" @click="showEmailLimitError = false">&times;</span>
                <h3>Email Template Limit Reached</h3>
                <p>You reached to the maximum limit of Email Template for
                  the {{ selectedBotTier }} tier.</p>
                <button @click="showEmailLimitError = false">OK</button>
              </div>
            </div>
            <button class="del-btn" @click="deleteItem('emails', index)">Delete Template</button>
          </div>

          <!-- Data Source Section -->
          <div class="data-source" v-if="showDataSourceSection">
            <hr class="section">
            <h4>Data Source</h4>
            <p>
              Data Source section
            </p>
            <textarea id="data-source-summary" cols="110" rows="10" placeholder="Enter Data Source summary here"
              ref="aiSummary" v-model="dataSourceSummary"></textarea>
            <span v-if="dataSourceSummary.length > 0"> {{ dataSourceSummary.length }}/10,000</span>
          </div>

          <!-- Text/sms Section -->
          <div class="sms-module" v-if="showSmsSection">
            <hr class="section">
            <h4>Text/SMS</h4>
            <p>The bot can send SMS messages</p>
            <div class="form-group" v-for="(sms, index) in smsMessages" :key="index">
              <div class="input-group">
                <div class="name-phrase">
                  <label :for="`sms-trigger-${index}`">{{ index + 1 }}. Trigger Phrase:</label>
                  <input type="text" :id="`sms-trigger-${index}`"
                    placeholder="Example: I want to register myself for the offer" v-model="sms.trigger" />
                </div>
                <div class="name-phrase">
                  <label :for="`sms.link-${index}`">Link:</label>
                  <input type="text" :id="`sms.link-${index}`" placeholder="Example: www.trimateai.com"
                    v-model="sms.link" />
                </div>
              </div>
              <label :for="`sms-template-${index}`" style="text-align: left;">SMS :</label>
              <textarea :id="`sms-template-${index}`"
                placeholder="Example: Thank you for calling Trimate AI! For a chance to win $3,000 in prizes, visit <link> and complete the form to enter."
                v-model="sms.template" rows="10"></textarea>
            </div>
            <button @click="addSms">Add SMS Template</button>
            <button class="del-btn" @click="deleteItem('smsMessages', index)">Delete SMS Template</button>
          </div>



          <!-- Review Section -->
          <div class="Rewiew" v-if="showReviewsSection">
            <hr class="section">
            <h4>Reviews</h4>
            <p>
              Reviews
            </p>
            <textarea id="Review-summary" cols="110" rows="10" placeholder="Enter review summary here" ref="aiSummary"
              v-model="reviewSummary"></textarea>
            <span v-if="reviewSummary.length > 0"> {{ reviewSummary.length }}/10,000</span>
          </div>

          <!-- CRM section -->
          <div class="crm" v-if="showCrmSection">
            <hr class="section">
            <h4>CRM</h4>
            <p>
              CRM
            </p>
            <textarea id="Crm-summary" cols="110" rows="10" placeholder="Enter CRM here" ref="crmSummary"
              v-model="crmSummary"></textarea>
            <span v-if="crmSummary.length > 0"> {{ crmSummary.length }}/10,000</span>
          </div>

          <!-- Payment section -->
          <div class="payments" v-if="showPaymentsSection">
            <hr class="section">
            <h4>Payments</h4>
            <p>
              Payment
            </p>
            <textarea id="Payments-summary" cols="110" rows="10" placeholder="Enter payment summary here"
              ref="aiSummary" v-model="paymentSummary"></textarea>
            <span v-if="paymentSummary.length > 0"> {{ paymentSummary.length }}/10,000</span>
          </div>
          <!-- Generative AI Section -->
          <div class="generative-ai" v-if="showGenAiSection">
            <hr class="section">
            <h4>Generative AI</h4>
            <p>
              Enable your bot to generate responses to questions outside the FAQ
              scope.
            </p>
            <textarea id="ai-summary" cols="110" rows="10" placeholder="Enter summary here" ref="aiSummary"
              v-model="aiSummary"></textarea>
            <span v-if="aiSummary.length > 0"> {{ aiSummary.length }}/10,000</span>
          </div>
          <button class="btn-submit" @click="downloadExcel">Download Excel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as XLSX from 'xlsx';

export default {
  name: "BotDiscovery",

  data() {
    return {
      botName: "",
      selectedBotType: "",
      selectedBotTier: "",
      selectedFeatures: [],
      formattedDate: null,
      aiSummary: "",
      reviewSummary: "",
      crmSummary: "",
      paymentSummary: "",
      textSmsSummary: "",
      dataSourceSummary: "",
      greeting: '',
      noMatchAction: '',
      noInputAction: '',
      suggestions: [],
      suggestionIndex: [],

      // visibiltity  of each section
      showFaqSection: false,
      showTieredFaqSection: false,
      showCallTransferSection: false,
      showBookingSection: false,
      showDataCollectionSection: false,
      showEmailSection: false,
      showSmsSection: false,
      showGenAiSection: false,
      showDataSourceSection: false,
      showWebsiteScrapingSection: false,
      showDataStoreSection: false,
      showReviewsSection: false,
      showCrmSection: false,
      showPaymentsSection: false,

      // error model 
      showFaqLimitError: false,
      showTieredFaqLimitError: false,
      showCallTransferLimitError: false,
      showDataCollectionLimitError: false,
      showBookingLimitError: false,
      showEmailLimitError: false,
      showDataStore: false,

      // data of each section
      faqs: [{
        name: "location",
        phrase: "Where are you located? What is your location?",
        response: ""
      },
      {
        name: "Operating Hours",
        phrase: "What are your opening hours?,Are you open now?,are you open during  weekend?",
        response: ""
      },
      {
        name: "Bot Capabilities",
        phrase: "What can you help me with?,What can i ask you?,what can you do?",
        response: ""
      },
      {
        name: "Services",
        phrase: "What services do you offer?,What are your service offerings?",
        response: ""
      },

      ],
      tieredFaqs: [
        {
          name: "",
          phrase: "",
          conditions: [
            {
              parameter: "",
              value: "",
              response: "",
            },
          ],
        },
      ],

      callTransfers: [
        {
          destination: "",
          did: ""
        }
      ],
      bookings: [
        {
          name: "",
          email: "",
        },
      ],
      dataCollections: [
        {
          question: "What is your full name?",
          modules: {
            booking1: true,
            sendEmail1: false,
            sendEmail2: false,
            sendEmail3: false,
          },
          comment: "",
        },
        {
          question: "Virtual or In-person?",
          modules: {
            booking1: true,
            sendEmail1: false,
            sendEmail2: false,
            sendEmail3: false,
          },
          comment: "",
        },
        {
          question: "What is your preferred Date?",
          modules: {
            booking1: true,
            sendEmail1: false,
            sendEmail2: false,
            sendEmail3: false,
          },
          comment: "",
        },

      ],
      emails: [
        {
          trigger: "I have an invoice question?",
          address: "",
          template: "Greetings from trimate AI!\n\nThe invoice number TA1111003 for James had an issue with PC purchase line item.\nCall back number: 614XXCXXXX\n\nThank You!"
        },
      ],
      smsMessages: [
        {
          trigger: "",
          link: "",
          template: ""
        }
      ]

    };
  },
  methods: {
    // function for Add Faq
    addFaq() {
      const maxFaqs = {
        PoC: 5,
        support: 15,
        sales: 30,
        voicePoC: 5,
        business: 15,
        professional: 30,
        'chat-enterprise': Infinity,
        'voice-enterprise': Infinity
      }[this.selectedBotTier];

      if (this.faqs.length >= maxFaqs) {
        this.showFaqLimitError = true;
        return;
      }

      this.faqs.push({
        name: "",
        phrase: "",
        response: "",
      });
    },
    // function for Add TFAQ
    addTieredFaq() {
      const maxTfaqs = {
        professional: 3,
        sales: 3,
        'chat-enterprise': Infinity,
        'voice-enterprise': Infinity
      }[this.selectedBotTier];

      if (this.tieredFaqs.length >= maxTfaqs) {
        this.showTieredFaqLimitError = true;
        return;
      }
      this.tieredFaqs.push({
        name: "",
        phrase: "",
        conditions: [
          {
            parameter: "",
            value: "",
            response: "",
          }
        ]
      });
    },
    // function for Add TFAQ Condition
    addCondition(tfaqIndex) {
      this.tieredFaqs[tfaqIndex].conditions.push({
        parameter: "",
        value: "",
        response: "",
      });
    },
    // Delete condition
    deleteCondition(tfaqIndex, conditionIndex) {
      this.tieredFaqs[tfaqIndex].conditions.splice(conditionIndex, 1);
    },
    // function for call transfer
    addCallTransfer() {
      const maxcallTransfers = {
        voicePoC: 1,
        business: 10,
        professional: 25,
        'voice-enterprise': Infinity
      }[this.selectedBotTier];
      if (this.callTransfers.length >= maxcallTransfers) {
        this.showCallTransferLimitError = true;
        return;
      }
      this.callTransfers.push({
        destination: "",
        did: ""
      })
    },
    // function for Add booking
    addBooking() {
      const maxBookings = {
        sales: 3,
        professional: 3,
        'voice-enterprise': Infinity,
        'chat-enterprise': Infinity
      }[this.selectedBotTier];
      if (this.bookings.length >= maxBookings) {
        this.showBookingLimitError = true;
        return;
      }

      this.bookings.push({
        name: "",
        email: "",

      });

    },
    // funtion for Add  data collection
    addDataCollection() {
      const maxdataCollections = {
        PoC: 2,
        voicePoC: 2,
        support: 3,
        sales: 10,
        professional: 10,
        'voice-enterprise': Infinity,
        'chat-enterprise': Infinity
      }[this.selectedBotTier];
      if (this.dataCollections.length >= maxdataCollections) {
        this.showDataCollectionLimitError = true;
        return;
      }
      this.dataCollections.push({
        question: "",
        modules: {
          booking1: false,
          booking2: false,
          booking3: false,
          sendEmail1: false,
          sendEmail2: false,
          sendEmail3: false,
        },
        comment: "",

      })
    },
    // function for Add email
    addEmail() {
      const maxemails = {
        PoC: 1,
        voicePoC: 1,
        support: 1,
        sales: 3,
        professional: 3,
        'voice-enterprise': Infinity,
        'chat-enterprise': Infinity
      }[this.selectedBotTier];
      if (this.emails.length >= maxemails) {
        this.showEmailLimitError = true
        return;
      }
      this.emails.push({
        trigger: "",
        address: "",
      });
    },
    addSms() {
      this.smsMessages.push({
        trigger: "",
        link: "",
        template: ""
      });
    },

    // Global Delete Function
    deleteItem(arrayName, index) {
      this[arrayName].splice(index, 1);

    },
    //  Data store modal
    showDataStoreMessage() {
      if (this.showDataStoreSection) {
        this.showDataStore = true;
      }
    },
    // Download Excel sheet
    downloadExcel() {
      // 1. Gather Data
      const data = [
        {
          'Bot Name': this.botName,
          'Bot Type': this.selectedBotType,
          'Bot Tier': this.selectedBotTier,
          'Subscription Start Date': this.formattedDate,
          'Greeting': this.greeting,
          'No Match Action': this.noMatchAction,
          'No Input Action': this.noInputAction,
        },
      ];
      // FAQs Data
      const faqsData = this.faqs.map((faq) => ({
        'FAQ Name': faq.name,
        'Example Phrase': faq.phrase,
        'Response': faq.response,
      }));
      // Tiered FAQs Data
      const tieredFaqsData = this.tieredFaqs.map((tfaq) => ({
        'Tiered FAQ Name': tfaq.name,
        'Example Phrase': tfaq.phrase,
        'Conditions': tfaq.conditions.map((condition) =>
          `IF ${condition.parameter} = ${condition.value} THEN ${condition.response}`
        ).join('\n'),
      }));
      // Call Transfers Data
      const callTransfersData = this.callTransfers.map((transfer) => ({
        'Destination': transfer.destination,
        'DID Number': transfer.did,
      }));
      // Booking Module Data
      const bookingsData = this.bookings.map((booking) => ({
        'Booking Name': booking.name,
        'Email Address': booking.email,
      }));
      // Data Collection Data
      const dataCollectionsData = this.dataCollections.map((dataCollection) => ({
        'Question': dataCollection.question,
        'Modules': Object.keys(dataCollection.modules)
          .filter(key => dataCollection.modules[key])
          .join(', '),
        'Comment': dataCollection.comment,
      }));
      // Email Module Data
      const emailsData = this.emails.map((email) => ({
        'Trigger Phrase': email.trigger,
        'Email Address': email.address,
      }));

      // Generative AI Data
      let genAiSummary = '';
      const aiSummaryElement = document.getElementById('ai-summary');
      if (aiSummaryElement) {
        genAiSummary = aiSummaryElement.value;
      }
      const genAiData = [{
        'Generative AI Summary': genAiSummary,
      }];
      // Review Data
      const reviewData = [{
        'Review Summary': this.reviewSummary,
      }];

      // Payment Data
      const paymentData = [{
        'Payment Summary': this.paymentSummary,
      }];

      // Data Source Data
      const dataSourceData = [{
        'Data Source Summary': this.dataSourceSummary,
      }];

      // CRM Data
      const crmData = [{
        'CRM Summary': this.crmSummary,
      }];

      // Text/SMS Data
      const smsMessagesData = this.smsMessages.map((sms) => ({
        'Trigger Phrase': sms.trigger,
        'Link': sms.link,
        'SMS Template': sms.template,
      }));

      // 2. Create Workbook and Worksheets
      const wb = XLSX.utils.book_new();

      function addDataToSheet(data, sheetName) {
        const ws = XLSX.utils.aoa_to_sheet([Object.keys(data[0])]);
        XLSX.utils.sheet_add_json(ws, data, {
          origin: 'A2',
          skipHeader: true,
          cellStyles: true
        });
        const range = XLSX.utils.decode_range(ws['!ref']);


        const headerStyle = {
          font: { bold: true, color: { rgb: 'FFFFFF' } },
          fill: { patternType: "solid", fgColor: { rgb: '0D0D0D' } },
          alignment: { horizontal: 'center' }
        };
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
          ws[cellAddress].s = headerStyle;
        }


        const rowStyle = {
          fill: { fgColor: { rgb: '7A8F92' } },
          font: { color: { rgb: '000000' } }
        };
        for (let row = 2; row <= range.e.r; row++) {
          for (let col = range.s.c; col <= range.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            ws[cellAddress].s = rowStyle;
          }
        }


        ws['!cols'] = [];
        for (let col = range.s.c; col <= range.e.c; col++) {
          ws['!cols'].push({ wch: 20 });
        }

        XLSX.utils.book_append_sheet(wb, ws, sheetName);
      }

      addDataToSheet(data, 'Bot Data');
      addDataToSheet(faqsData, 'FAQs');
      addDataToSheet(tieredFaqsData, 'Tiered FAQs');
      addDataToSheet(callTransfersData, 'Call Transfers');
      addDataToSheet(bookingsData, 'Bookings');
      addDataToSheet(dataCollectionsData, 'Data Collection');
      addDataToSheet(emailsData, 'Emails');
      addDataToSheet(genAiData, 'Generative AI');
      addDataToSheet(reviewData, 'Reviews');
      addDataToSheet(paymentData, 'Payments');
      addDataToSheet(dataSourceData, 'Data Source');
      addDataToSheet(crmData, 'CRM');
      addDataToSheet(smsMessagesData, 'SMS Messages');

      // 3. Generate Excel File
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

      // 4. Create Blob and Download Link
      const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(dataBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'bot_data.xlsx');
      document.body.appendChild(link);
      link.click();
    },
    // reset values of form 
    reset() {

      this.showFaqSection = false;
      this.showTieredFaqSection = false;
      this.showCallTransferSection = false;
      this.showBookingSection = false;
      this.showDataCollectionSection = false;
      this.showEmailSection = false;
      this.showSmsSection = false;
      this.showGenAiSection = false;
      this.showDataSourceSection = false;
      this.showWebsiteScrapingSection = false;
      this.showDataStoreSection = false;
      this.showReviewsSection = false;
      this.showCrmSection = false;
      this.showPaymentsSection = false;

      this.faqs = [
        {
          name: "location",
          phrase: "Where are you located? What is your location?",
          response: ""
        },
        {
          name: "Operating Hours",
          phrase: "What are your opening hours?,Are you open now?,are you open during  weekend?",
          response: ""
        },
        {
          name: "Bot Capabilities",
          phrase: "What can you help me with?,What can i ask you?,what can you do?",
          response: ""
        },
        {
          name: "Services",
          phrase: "What services do you offer?,What are your service offerings?",
          response: ""
        }
      ];

      this.tieredFaqs = [
        {
          name: "",
          phrase: "",
          conditions: [
            {
              parameter: "",
              value: "",
              response: "",
            },
          ],
        },
      ];

      this.callTransfers = [
        {
          destination: "",
          did: ""
        }
      ];

      this.bookings = [
        {
          name: "",
          email: "",
        },
      ];

      this.dataCollections = [
        {
          question: "What is your full name?",
          modules: {
            booking1: true,
            sendEmail1: false,
            sendEmail2: false,
            sendEmail3: false,
          },
          comment: "",
        },
        {
          question: "Virtual or In-person?",
          modules: {
            booking1: true,
            sendEmail1: false,
            sendEmail2: false,
            sendEmail3: false,
          },
          comment: "",
        },
        {
          question: "What is your preferred Date?",
          modules: {
            booking1: true,
            sendEmail1: false,
            sendEmail2: false,
            sendEmail3: false,
          },
          comment: "",
        },
      ];

      this.emails = [
        {
          trigger: "I have an invoice question?",
          address: "",
          template: "Greetings from trimate AI!\n\nThe invoice number TA1111003 for James had an issue with PC purchase line item.\nCall back number: 614XXCXXXX\n\nThank You!"
        },
      ];

      this.aiSummary = "";
      this.reviewSummary = "";
      this.crmSummary = "";
      this.paymentSummary = "";
      this.textSmsSummary = "";
      this.dataSourceSummary = "";
    },
    // suggetion method
    async getSuggestions(index) {
      const currentFaq = this.faqs[index];
      if (!currentFaq || !currentFaq.phrase || currentFaq.phrase.length < 3) {
        this.suggestions[index] = [];
        return;
      }


      try {
        const newSuggestions = this.fetchSuggestions(currentFaq.phrase);

        if (this.faqs[index] === currentFaq) {
          this.suggestions[index] = newSuggestions;
          this.suggestionIndex[index] = 0;
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        this.suggestions[index] = [];
      }
    },

    fetchSuggestions(phrase) {
      console.log(phrase)
      const exampleSuggestions = [
        "Where are you located? What is your location?",
        "What is your contact? How can I contact you?",
        "What are you opening hours? Are you open now? Are you open during weekends?",
        "What can you help me with? What can I ask you? What can you do?",
        "Do you offer managed services? Tell me more about your managed services.",
        "What services do you offer? What are your service offerings?",
        "What products do you offer? What are your product offerings?",
        "Can I get a quote for your services? I need a quote. I want to get a quote for a new laptop.",
        "What is the cost of appointment, How much do you charge for a consultation?",
        "what is bulk billing? What is Essential 8? What is XXX?",
        "what is the email address for sales? What is the email address for support? Can you give me accounts email address?",
        "Can you provide directions to your office? Are you close to public transport? How far are you from public transport?",
        "Is there parking available? Where can I park my car when I visit your office?",
        "What precautions are you taking for COVID 19? Should I be aware of any rules to follow for COVID-19?",
        "Do you offer any discounts? Do you offer any free consultation?",
        "How can I sign up for your newsletter? How can I stay updated with your latest service offerings?",
        "Do you have a lost and Found? I lost something when I was at your office.",
        "Can you tell me about your company's privacy policy? Tell me more about privacy policies.",
        "How can I unsubscribe from your marketing emails? Can you stop sending me marketing emails.",
        "Are there any job openings? How can I apply for a job with you?",
        "What is your cancellation policy? What are your rules around cancellation?",
        "Do you have a mobile application? Can I do this through a mobile application?",
        "How can I get more information about your company? Can you tell me more about your organisation?",
        "Are you a bulk billing clinic? Do you offer bulk billing?",
        "What insurances do you accept? Do you accept health insurances?",
        "Do you offer Telehealth? Do you offer virtual appointments?",
        "Do you offer Pathology services? Do you have pathology?",
        "Do you have chemist? Is there a chemist close by?",
        "What should I bring for my appointment? What documents do you need for appointment?",
        "Do you have any specialists? Do you offer any specialist services?",
        "What are your cybersecurity offerings? Do you offer Essential 8 assessments?",
        "Do you offer after hours support? Do you have a 24X7 support?",
        "What industries do you specialize in? What industries do you usually serve?",
        "Can you provide references from other clients? Are there any case studies?",
        "How do you handle data security? How do you keep your data secure",
        "What is your onboarding process for new clients? How long does it take to onboard new customers? Descibe the onboarding process.",
        "What is the process of critical incidents?  How do you handle critical incidents.",
        "What are your SLAs? Can you give me an outline of your SLAs",
        "How can I lodge a support ticket? How is process of logging a support ticket.",
        "How can I track my support tickets? Do you offer a ticket tracking system? How can I see status of my ticket",
        "What is included in your managed services? What are your managed services inclusions.",
        "Do you sell Hardware? Can I buy a laptop?",
        "Do you sell Software? Can I buy antivirus?",
        "What kind of Analytics do you provide? What is your analytics platform",
        "Do you adhered data and security complaince? What complaince policies do you follow?",
        "Do you offer support for mobile devices? Do you have a Mobile device management service?",
        "What cloud services do you offer? Do you offer Azure Cloud services?",
        "What backup solutions do you offer? Do you offer backup solutions?",
      ];
      return exampleSuggestions.filter(sug => sug.toLowerCase().includes(phrase.toLowerCase()));
    },

    selectSuggestion(index, sIndex = null) {
      if (sIndex !== null) {
        this.faqs[index].phrase = this.suggestions[index][sIndex];
      } else {
        if (this.suggestions[index] && this.suggestions[index].length && this.suggestionIndex[index] !== -1) {
          this.faqs[index].phrase = this.suggestions[index][this.suggestionIndex[index]];
        }
      }
      this.suggestions[index] = [];
      this.suggestionIndex[index] = -1;

    },
  },
  computed: {
    isChatBot() {
      return this.selectedBotType === "chat";
    },
    isVoiceBot() {
      return this.selectedBotType === "voice";
    },
    // Date validation
    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, '0');
      const day = today.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  },
  watch: {
    selectedBotTier: function () {
      this.reset();
    }
  }

};
</script>
<style scoped>
.dashboard {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #fff;
}

.content {
  width: 100%;
  max-width: 980px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

hr.h1 {
  width: auto;
}

hr.section {
  height: 0.5px;
  background-color: #7A8F92;
  margin: 25px 0;
}

h3.header {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

h4 {
  font-size: 18px;
  font-family: 'Montserrat';
}

p {
  text-align: left;
  margin-bottom: 10px;
}

.icon {
  margin-left: 5px;

}

input:focus-visible,
textarea:focus-visible,
select:focus-visible {
  border: 1px solid #e45f2b !important;
  outline: none !important;
}

/* Greeting Section Styling */
.greeting-section .input-group {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 15px;
}

.greeting-section label {
  width: 25%;
  text-align: left;
}

.greeting-section textarea {
  width: 75%;
  resize: vertical;
}


/* Feature List Group Styling */
.feature-list-group {
  margin-bottom: 20px;
}

.feature-list-group ul {
  padding-left: 20px;
  margin-top: 0;
}

.feature-list-group li {
  margin-bottom: 5px;
  text-align: left;
}

/* Board-Details card */
.bot-details-card-group {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.bot-type-section {

  border-radius: 5px;
  padding: 20px;
  text-align: left;
  vertical-align: top;
  white-space: normal;

}

/* bot-details-card-group card */
.card-grid {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.card {
  border: 1px solid #e45f2b;
  border-radius: 8px;
  padding: 15px;
  position: relative;
  cursor: pointer;
  flex: 1;
  box-shadow: 4px 3px 8px rgba(0, 0, 0, 0.1);
}

.card input[type="radio"] {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Form Container */
.form-container {
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

/* Bot Details Section Styling */
.bot-details .form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.bot-details .form-group label {
  display: inline-block;
  width: 150px;
  text-align: left;
  margin-bottom: 0;
}

.bot-details .form-group input,
.bot-details .form-group select {
  display: inline-block;
  width: calc(100% - 160px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.bot-features {
  margin-bottom: 20px;
}

.bot-features h4 {
  margin-bottom: 10px;
}

.bot-features p {
  margin-bottom: 15px;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 8px;
}

/* FAQ Section Styling */
.faq-section .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.faq-section .input-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.faq-section .input-group label {
  width: 120px;
  margin-bottom: 0;
  white-space: nowrap;
}

.faq-section .name-phrase {
  display: flex;
  align-items: center;
  margin-right: 20px;
  gap: 10px;

}

.faq-section .input-group input[type="text"],
.faq-section .input-group textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.faq-section .input-group input[id^="faqName"] {
  width: 200px;
}

.faq-section .input-group input[id^="faqPhrase"] {
  width: 450px;
}

.faq-section .input-group textarea {
  width: 100%;
  height: 80px;
}

/* Tiered FAQs Section Styling */
.tiered-faq-section .form-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.tiered-faq-section .form-group label {
  display: inline-block;
  width: 130px;
  text-align: left;
  margin-bottom: 0;
  white-space: nowrap;
}

.tiered-faq-section .form-group input[type="text"],
.tiered-faq-section .form-group textarea {
  display: inline-block;
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.tiered-faq-section .form-group textarea {
  height: 120px;
  width: 600px;

}

/* Styling for the conditional responses section */
.conditional-responses {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  background-color: #fafafa;
  margin-bottom: 20px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.if-then-group {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.if-then-group label {
  white-space: nowrap;
}

/* Make textareas taller */
.if-then-group textarea {
  height: 60px;
  width: 200px;
}

.tiered-faq-section .form-group input[id^="tfaq-phrase-"] {
  width: 400px;
}

/* Call Transfer Section Styling */
.call-transfer-section .form-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.call-transfer-section .form-group label {
  display: inline-block;
  width: 120px;
  text-align: left;
  margin-bottom: 0;
}

.call-transfer-section .form-group input[type="text"] {
  display: inline-block;
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

/* Booking Module Section Styling */
.booking-module .form-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.booking-module .form-group label {
  display: inline-block;
  width: 120px;
  text-align: left;
  margin-bottom: 0;
}

.booking-module .form-group input[type="text"],
.booking-module .form-group input[type="email"] {
  display: inline-block;
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

/* Data Collection Section Styling */
.data-collection .form-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.data-collection .form-group label {
  display: inline-block;
  width: 120px;
  text-align: left;
  margin-bottom: 0;
}

.data-collection .form-group input[type="text"],
.data-collection .form-group textarea,
.data-collection .form-group .dropdown {
  display: inline-block;
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.data-collection .dropdown-input {
  width: 100%;
  cursor: pointer;
}

.data-collection .form-group textarea {
  height: 80px;
}

.data-collection .form-group label:nth-child(1),
.data-collection .form-group input[type="text"]:nth-child(2) {
  width: calc(50% - 5px);
}

.data-collection .form-group label:nth-child(3),
.data-collection .form-group .dropdown:nth-child(4) {
  width: calc(50% - 5px);
}

.data-collection .form-group label:nth-child(5),
.data-collection .form-group textarea:nth-child(6) {
  width: 100%;
  text-align: left;
}

.data-collection .dropdown-content label {
  display: block;
  padding: 8px;
  height: auto;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 5px;
}

.data-collection .dropdown-content label input[type="checkbox"] {
  margin-right: 8px;
}

/* Email Section Styling */
.sms-module .form-group,
.email-module .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.sms-module .input-group,
.email-module .input-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.sms-module .name-phrase,
.email-module .name-phrase {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.sms-module .name-phrase label,
.email-module .name-phrase label {
  text-align: left;
  width: 120px;
  margin-bottom: 0;
  margin-right: 10px;
  white-space: nowrap;
}

.sms-module .input-group input[type="text"],
.email-module .input-group input[type="text"],
.sms-module .input-group textarea,
.email-module .input-group textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.sms-module .input-group input[id^="sms-trigger"],
.email-module .input-group input[id^="email-trigger"] {
  width: 100%;
}

.sms-module .input-group input[id^="sms.link"],
.email-module .input-group input[id^="email-address"] {
  width: 100%;
}

.sms-module .input-group textarea,
.email-module .input-group textarea {
  width: 100%;
  height: 80px;
}

/* Generative-ai,crm,review,text/SMS,Data-source,payment */
.data-source textarea,
.text-sms textarea,
.payments textarea,
.crm textarea,
.Rewiew textarea,
.generative-ai textarea {
  position: relative;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  resize: vertical;
}

.data-source span,
.text-sms span,
.payments span,
.crm span,
.Rewiew span,
.generative-ai span {
  margin-left: 860px;
}


.del-btn {
  background-color: #e45f2b;
}

.del-btn:hover {
  background-color: black;
}

.btn-submit {
  margin-top: 50px;
}

/*Error  Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 30%;
  margin-top: 2px;
}

.close {
  position: relative;
  padding: 5px;
  left: 50%;
  font-size: 25px;
  cursor: pointer;
}

.form-group {
  position: relative;
  margin-bottom: 20px;
}

.suggestions {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  list-style: none;
  padding: 0;
  border: 1px solid #ccc;
  z-index: 1;
  background-color: white;
}

.suggestions li {
  padding: 5px;
  cursor: pointer;
  text-align: left;
}

.suggestions li.active,
.suggestions li:hover {
  background-color: #f0f0f0;
}
</style>
