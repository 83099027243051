<template>
  <div class="dashboard-container">
    <side-bar />
    <div class="main-content">
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd000b331508ad6fe45c0a1bd/emb" :is="'script'"></component>
      </div>

      <h2>Bot Creation Options</h2>
      <hr class="h2" />
      <p>Choose one of the options below to get started</p>
      <div class="card-container">
        <div class="card">
          <div class="card-content">
            <p>Create a pre-configured demo bot to showcase bot capabilities</p>
          </div>
          <router-link to="/distributor/demo/main" class="btn-link">
            <button class="card-button">Setup demo bot</button>
          </router-link>
        </div>
        <div class="card">
          <div class="card-content">
            <p>Provision a new custom bot in a few simple steps using the bot wizard</p>
          </div>
          <router-link to="/distributor/bot-wizard/main" class="btn-link">
            <button class="card-button">Custom bot wizard</button>
          </router-link>
        </div>
        <div class="card">
          <div class="card-content">
            <p>Create a discovery sheet for capturing the requirements of a new bot</p>
          </div>
          <router-link to="/bot-discovery" class="btn-link">
            <button class="card-button">Bot discovery</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
  name: 'DistiNewBot',
  components: {
    SideBar,
  },
}
</script>

<style scoped>
.sidebar {
  max-width: 10vw;
}

.flex-container {
  display: flex;
  flex-direction: row;
}

h3 {
  margin-left: 20px;
  font-size: 24px;
  font-weight: normal;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-content: center;
  gap: 10px;
  margin: 20px;
}

.card {
  width: 300px;
  margin: 20px;
  border: 1px solid #e45f2b;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

}

p {
  text-align: left;
  margin-left: 20px;
}

.card-content {
  margin-bottom: 10px;
}

.card-button {
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.btn-link {
  text-decoration: none;
}
</style>