<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <h2>Demo Bot Setup</h2>
            <hr class="h2">
            <div class="wizard-steps">
                <div class="completed">
                    <div class="num">&#10003;</div> <span class="step">Basic Details</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="current">
                    <div class="num">2</div> <span class="step">View FAQs</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="num">3</div>Transfers and Emails
                <span class="arrow">&#8594;</span>
                <div class="num">4</div> Test and Finish
            </div>
            <p>Following are the pre-configured features of the demo bot.</p>
            <div class="tables-list">
                <div class="table">
                    <h4>General FAQs</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Response</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Can you speak different languages? What languages do you speak?</td>
                                <td>Yes, our AI bots support multiple languages and can translate conversations in
                                    real-time, enabling your customers to communicate in their preferred language. This
                                    ensures smooth interactions regardless of region or language, making your services
                                    more accessible.</td>
                            </tr>
                            <tr>
                                <td>What are your operating hours? What are your business hours?</td>
                                <td>Operating hours are Monday to Friday, 9am to 5pm. We are closed on weekends and
                                    puglic holidays.
                                </td>
                            </tr>
                            <tr>
                                <td>What can you help me with? What can I ask you? What can you do?</td>
                                <td>We help businesses automate tasks like collecting and sharing information over the
                                    phone, sending booking links, and integrating with systems like CRMs and appointment
                                    schedulers. Our AI bots handle routine queries, process customer data, and
                                    seamlessly update your systems, streamlining workflows and enhancing customer
                                    service.
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="table">
                    <h4>Medical FAQs</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Response</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>How long is the wait time? What is the typical wait time? How long will I have to
                                    wait?</td>
                                <td>The wait time varies depending on clinical needs. Our team will assist as quickly as
                                    possible, and we appreciate your patience.</td>
                            </tr>
                            <tr>
                                <td>What are the visiting hours? When can I visit the patient?</td>
                                <td>Visiting hours are daily from 10am to 4pm.</td>
                            </tr>
                            <tr>
                                <td>Do you have radiology? Is there radiology in the hospital? Where is radiology?</td>
                                <td>Yes, radiology services are available on-site, located on the ground floor near the
                                    main reception.
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="table">
                    <h4>Car Dealer FAQs</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Response</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Do you have a Toyota Camry in stock? Do you have a Toyota Camry available?</td>
                                <td>We have a wide range of vehicles, including sedans, SUVs, trucks, and electric
                                    models. I’m connected to our stock system, so I can check real-time availability.
                                    Just let me know what you’re after!</td>
                            </tr>
                            <tr>
                                <td>Can I schedule a test drive? How can I schedule a test drive?</td>
                                <td>Test drives can be scheduled over the phone. Simply provide the make and model
                                    you're interested in, and I'll pass this on to the team to book it in.</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="table">
                    <h4>Real Estate FAQs</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Response</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>What is the process of renting a property? What documents do I need to rent a
                                    property?</td>
                                <td>Renting typically requires a valid ID, proof of income, and rental references. We'll
                                    review these to ensure a quick process.</td>
                            </tr>

                            <tr>
                                <td>When can I inspect the property? How can I inspect the property?</td>
                                <td>Property inspections can be arranged with the property manager. Please have the
                                    address ready, and I'll assist with the arrangement.
                                </td>
                            </tr>


                        </tbody>
                    </table>
                </div>
                <div class="table">
                    <h4>Banking FAQs</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Response</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>How can I apply for a loan? I need a car loan. I want to apply for a home loan.</td>
                                <td>Loan applications are available for cars, homes, and personal use. Let me know what
                                    you're looking for, and I'll guide you through the process.</td>
                            </tr>
                            <tr>
                                <td>What should I do if I lost my ATM card? I lost my debit card. Someone stole my
                                    credit card.</td>
                                <td>I’ll transfer you to customer service immediately to block the card and arrange a
                                    replacement.
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="table">
                    <h4>Government FAQs</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Response</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>How do I report a pothole? My streetlight does not work. I want to make a noise
                                    complaint.</td>
                                <td>To report issues like potholes or complaints, please provide the location details,
                                    and I'll forward it to the team for action.</td>
                            </tr>
                            <tr>
                                <td>Where can I find information on the waste collection schedule? What day of the week
                                    will my bin be collected?</td>
                                <td>Waste collection schedules are available by phone; simply provide your address, and
                                    I'll find the correct day for you.
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="table">
                    <h4>Education FAQs</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Response</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>What is the school's policy on absences? What should I do if my child is going to be
                                    absent?</td>
                                <td>I can gather the necessary details and log them into our system, or pass you on to a
                                    person to notify the school of absences and keep records up-to-date.</td>
                            </tr>
                            <tr>
                                <td>How can I contact my child’s teacher? Is it possible to talk to my child’s teacher?
                                </td>
                                <td>'I'll pass along your details to the teacher, who will reach out to discuss with you
                                    shortly.
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="table">
                    <h4>MSP FAQs</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Response</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>I want to log a ticket. How can I log a ticket?</td>
                                <td>Ticket logging can be handled over the phone. I will gather the detials, and log
                                    them in the ticketing system. The team will then complete triage and get back to you
                                    ASAP.</td>
                            </tr>

                            <tr>
                                <td>Do you sell software? What sort of licenses do you sell? Can I buy an M365
                                    subscription?</td>
                                <td>We sell a variety of software solutions, including M365 subscriptions. Let me know
                                    your requirements, and i'll confirm available options for your business needs.
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="table">
                    <h4>Retail FAQs</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Response</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>What is your return or exchange policy? I want to return my item.</td>
                                <td>Returns and exchanges are accepted with proof of purchase. Refunds can be requested
                                    via your order confirmation email or in-store.</td>
                            </tr>


                        </tbody>
                    </table>
                </div>
                <div class="table">
                    <h4>Insurance FAQs</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Response</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>How can I make a claim? I want to initiate a claim request.</td>
                                <td>To initiate a claim, I'll need your policy number to locate you in our system.</td>
                            </tr>
                            <tr>
                                <td>What is included in my policy? What are your policy inclusions? Am I covered for an
                                    accident?</td>
                                <td>Policy coverage varies by plan. A summary of inclusions is available in your policy
                                    notice, which is avaialbe on our website or in the policy email we sent when you
                                    first took out the policy.</td>
                            </tr>


                        </tbody>
                    </table>
                </div>
                <div class="table">
                    <h4>Utilities FAQs</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Response</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Why is my bill so high? My bill is very high this month.</td>
                                <td>High bills can result from increased usage or rate changes. Please let us know if
                                    you would like to complete an account review, or claim for a inaccurate report.</td>
                            </tr>
                            <tr>
                                <td>What payment options are available? How can I make a payment? Can I pay using BPAY?
                                </td>
                                <td>Payment options include BPAY, direct debit, and credit card.</td>
                            </tr>


                        </tbody>
                    </table>
                </div>

            </div>
            <router-link to="/partner/demo/transfers">
                <button>Save and Next&#8594; </button>
            </router-link>
        </div>
    </div>
</template>
<script>
import SideBar from '../SideBar.vue'
export default {
    name: 'MainConfig',
    components: {
        SideBar
    }
}
</script>

<style scoped>
button {
    float: left;
    margin-top: 20px;
    margin-bottom: 10px;
}

p {
    text-align: left;
    padding-left: 10px;
}

h4 {
    text-align: left;
    padding-left: 10px;
    font-size: 25px;
}

.tables-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.table {
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
}

.table table {
    width: 100%;
    border-collapse: collapse;
}

.table th,
.table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

.table th {
    background-color: #f5f5f5;
    font-weight: bold;
}

.table td {
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
