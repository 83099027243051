<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd000aec87c7310e84ca7b0b5/emb" :is="'script'">
                </component>
            </div>
            <div class="flex-container">
                <div class="left-section">
                    <h2>Generative AI</h2>
                    <hr class="h2" />
                    <div class="container">
                        <component src="https://c2hbe361.caspio.com/dp/76bfd000b46b0b70505e4d5c8e21/emb" :is="'script'">
                        </component>
                    </div>
                </div>
                <div class="right-section">
                    <!--Test agent-->
                    <div class="container">
                        <component src="https://c2hbe361.caspio.com/dp/76bfd000155ff238df2743998991/emb" :is="'script'">
                        </component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from '../SideBar.vue'

export default {
    name: 'BotGenAI',
    components: {
        SideBar
    }
}
</script>

<style scoped>
.flex-container {
    display: flex;
    flex-direction: row;
}

.right-section {
    max-width: 420px;
}
</style>