<template>
  <div class="dashboard-container">
    <side-bar />
    <div class="main-content">
      <h2>Account and User Settings</h2>
      <hr class="h2" />
      <div class="tabs">
        <div class="tab-header">

          <button class="tab-button" @click="activeTab = 'user-profile'"
            :class="{ active: activeTab === 'user-profile' }">User Profile</button>
          <button class="tab-button" @click="activeTab = 'company-profile'"
            :class="{ active: activeTab === 'company-profile' }">Company Profile</button>
          <button class="tab-button" @click="activeTab = 'users'" :class="{ active: activeTab === 'users' }">All
            Users</button>
        </div>
        <div class="tab-content">
          <div v-if="activeTab === 'user-profile'">
            <div class="container">
              <component src="https://c2hbe361.caspio.com/dp/76bfd000afb091485db142179553/emb" :is="'script'">
              </component>
            </div>
          </div>
          <div v-if="activeTab === 'company-profile'">
            <div class="container">
              <component src="https://c2hbe361.caspio.com/dp/76bfd000ca36f6244dd94a9688fd/emb" :is="'script'">
              </component>
            </div>
          </div>
          <div v-if="activeTab === 'users'">

            <!--check Admin access HTML script-->
            <div class="container">
              <component src="https://c2hbe361.caspio.com/dp/76bfd000992f7f3451644d608d5b/emb" :is="'script'">
              </component>
            </div>

            <div id="adsfartessg">
              <!--Register new user button-->
              <div class="container">
                <button v-on:click="showForm" v-if=!isFormOpen id="btn-new">
                  <font-awesome-icon icon="fa-user-plus" />
                  Register New User
                </button>
              </div>
              <!--Register new user form-->
              <div class="container" v-if=isFormOpen>
                <component src="https://c2hbe361.caspio.com/dp/76bfd0008132dad331614954883d/emb" :is="'script'">
                </component>
              </div>
              <!--View and edit all users-->
              <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd00036ca5e77410b47b1920b/emb" :is="'script'">
                </component>
              </div>
            </div>
            <div id="msg-non-admin">
              Sorry, this feature is restricted to admin users.
            </div>
          </div>

        </div>
      </div>

      <!-- <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd000afb091485db142179553/emb" :is="'script'"></component>
      </div>
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd000ca36f6244dd94a9688fd/emb" :is="'script'"></component>
      </div> -->
    </div>
  </div>
</template>


<script>
import SideBar from './SideBar.vue'

export default {
  name: 'UpdateProfile',
  components: {
    SideBar
  },
  data() {
    return {
      isFormOpen: false,
      activeTab: 'user-profile' // Default active tab
    }
  },
  methods: {
    showForm() {
      this.isFormOpen = true;
    },
  }
}
</script>

<style scoped>
.container.tiles {
  /* max-width: 980px; */
  margin-top: 40px;
  display: flex;
  flex-direction: row;
}

.container {
  margin: 10px 0;
}

#btn-new {
  margin: 10px 0;
}

#adsfartessg {
  display: none;
}

#msg-non-admin {
  display: none;
}
</style>