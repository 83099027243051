<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <div class="container">
                
            </div>
            <h2>Emails</h2>
            <hr class="h2" />
            <div class="container">
                
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from '../SideBar.vue'

export default {
    name: 'SendEmails',
    components: {
        SideBar
    }
}
</script>