<template>
    <div class="sidebar" :class="{ 'sidebar-collapsed': !isSidebarOpen }">
        <div class="sidebar-header">
            <button @click="toggleSidebar" class="sidebar-toggle">
                <font-awesome-icon icon="fa-bars" />
            </button>
        </div>
        <ul class="sidebar-nav">
            <li v-b-tooltip.hover title="Distributor Dashboard">
                <router-link to="/distributor/dashboard" class="nav-link">
                    <font-awesome-icon icon="fa-home" v-b-tooltip.hover title="Distributor Dashboard" />
                    <span v-if="isSidebarOpen">Dashboard</span>
                </router-link>
            </li>
            <li v-b-tooltip.hover title="Account and User Settings">
                <router-link to="/distributor/update-profile" class="nav-link">
                    <font-awesome-icon icon="fa-user-edit" v-b-tooltip.hover title="Account and User Settings" />
                    <span v-if="isSidebarOpen">Account</span>
                </router-link>
            </li>
            <li v-b-tooltip.hover title="Opportunities">
                <router-link to="/distributor/opportunities" class="nav-link">
                    <font-awesome-icon icon="fa-bullseye" v-b-tooltip.hover title="Opportunities" />
                    <span v-if="isSidebarOpen">Opportunities</span>
                </router-link>
            </li>
            <!-- <li>
                <router-link to="/distributor/new-partner" class="nav-link">
                    <font-awesome-icon icon="fa-user-plus" />
                    <span v-if="isSidebarOpen">New Partner Sign-up</span>
                </router-link>
            </li> -->
            <li v-b-tooltip.hover title="Manage Partners">
                <router-link to="/distributor/partners" class="nav-link">
                    <font-awesome-icon icon="fa-users" v-b-tooltip.hover title="Manage Partners" />
                    <span v-if="isSidebarOpen">Partners</span>
                </router-link>
            </li>
            <li v-b-tooltip.hover title="App Release History and Upcoming Releases">
                <router-link to="/distributor/releases" class="nav-link">
                    <font-awesome-icon icon="fa-bolt" v-b-tooltip.hover title="App Release History and Upcoming Releases"/>
                    <span v-if="isSidebarOpen">Release History</span>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
//import { ref } from 'vue';

export default {
    data() {
        return {
            isSidebarOpen: true,
        };
    },
    methods: {
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
    },
};
</script>

<style scoped>
.sidebar {
    width: 155px;
}

/* Style for collapsed sidebar */
.sidebar-collapsed {
    width: 40px;
}
</style>