<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <!--Breadcrumbs and title embedded within below data page-->
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd00090c5fa8f46784ed2b47e/emb" :is="'script'">
                </component>
            </div>
            <div class="tabs">
                <div class="tab-header">
                    <button class="tab-button" @click="activeTab = 'customers'"
                        :class="{ active: activeTab === 'customers' }">Customers</button>
                    <button class="tab-button" @click="activeTab = 'profile'"
                        :class="{ active: activeTab === 'profile' }">Profile</button>
                </div>
                <div class="tab-content">
                    <div v-if="activeTab === 'customers'">
                        <div class="container">
                            <component src="https://c2hbe361.caspio.com/dp/76bfd00015331f83c1b640a0a6b3/emb"
                                :is="'script'">
                            </component>
                        </div>
                    </div>
                    <div v-if="activeTab === 'profile'">
                        <div class="container">
                            <component src="https://c2hbe361.caspio.com/dp/76bfd000d3dd1ecbe5314687ab4f/emb"
                                :is="'script'">
                            </component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
    name: 'PartnerDetails',
    components: {
        SideBar
    },
    data() {
        return {
            activeTab: 'customers' // Default active tab
        }
    }
}
</script>
<style scoped>
.container.tiles {
    /* max-width: 980px; */
    margin-top: 40px;
    display: flex;
    flex-direction: row;
}

.container.lhs {
    flex: 1;
    margin-right: 20px;
}

.container.rhs {
    width: 350px;
}
</style>