<template>
  <div class="dashboard-container">
    <side-bar />
    <div class="main-content">
      <!--Breadcrumbs and title embedded within below data page-->
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd00014f8b8eb37eb4a46870e/emb" :is="'script'">
        </component>
      </div>

      <div class="tabs">
        <div class="tab-header">

          <button class="tab-button" @click="activeTab = 'call-logs'"
            :class="{ active: activeTab === 'call-logs' }">Call Logs</button>
          <button class="tab-button" @click="this.$router.push('/partner/conversation-history')"
            :class="{ active: activeTab === 'conv-history' }">Conversation History</button>
          <button class="tab-button" @click="this.$router.push('/partner/view-bot')"
            :class="{ active: activeTab === 'bot-config' }">Bot Configuration</button>
        </div>
        <div class="tab-content">
          <div v-if="activeTab === 'call-logs'">
            <div class="container">
              <component src="https://c2hbe361.caspio.com/dp/76bfd000e22a5a08992b4e859d6f/emb" :is="'script'">
              </component>
            </div>
            <div class="container">
              <component src="https://c2hbe361.caspio.com/dp/76bfd00087e4491d065640b08545/emb" :is="'script'">
              </component>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
  name: 'ViewLogs',
  components: {
    SideBar
  },
  data() {
    return {
      activeTab: 'call-logs' // Default active tab
    }
  },
}
</script>
