<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <h2>Demo Bot Setup</h2>
            <hr class="h2" />
            <div class="wizard-steps">
                <div class="completed">
                    <div class="num">&#10003;</div> <span class="step">Basic Details</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="completed">
                    <div class="num">&#10003;</div> <span class="step">View FAQs</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="completed">
                    <div class="num">&#10003;</div> <span class="step">Transfers and Emails</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="current">
                    <div class="num">4</div> <span class="step">Test And Finish</span>

                </div>
            </div>
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd000a31eb84860494757af74/emb" :is="'script'">
                </component>
            </div>
        </div>
    </div>
</template>
<script>
import SideBar from '../SideBar.vue'
export default {
    name: 'MainConfig',
    components: {
        SideBar
    }
}
</script>
